@import 'github-markdown-css/github-markdown.css';


@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}



// @font-face {
//   font-family: "Minion";
//   src: url("/assets/fonts/Minion/MinionPro-Bold.otf") format("opentype");
//   font-weight: 700;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Minion";
//   src: url("/assets/fonts/Minion/MinionPro-Medium.otf") format("opentype");
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Minion";
//   src: url("/assets/fonts/Minion/MinionPro-Regular.otf") format("opentype");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Minion";
//   src: url("/assets/fonts/Minion/MinionPro-Semibold.otf") format("opentype");
//   font-weight: 600;
//   font-style: normal;
// }

// @layer base {
//   input:-webkit-autofill,
//   input:-webkit-autofill:hover,
//   input:-webkit-autofill:focus {
//     -webkit-text-fill-color: white;
//     -webkit-box-shadow: inherit;
//     transition: background-color 5000s ease-in-out 0s;

//     @apply font-euclid text-16;
//   }
//   input:-webkit-autofill::first-line {
//     @apply font-euclid text-16;
//   }
// }

html {
  font-family: 'Poppins';
}

body{
  font-size: 16px;
  color: #434039;
  font-family: 'Poppins' !important;
}

.bg-blend-multiply{
  background-blend-mode: multiply;
}

.mix-blend-multiply{
  mix-blend-mode: multiply;
}

.flex-basis-100{
  flex-basis: 100%;
}

.flex-basis-33{
  flex-basis: 33%;
}

.flex-basis-30{
  flex-basis: 30%;
}

.flex-basis-25{
  flex-basis: 25%;
}

.flex-basis-20{
  flex-basis: 20%;
}

.flex-basis-50{
  flex-basis: 50%;
}

.flex-basis-40{
  flex-basis: 40%;
}

.collapsable {
  transition:height 0.3s ease-in-out;
  height:auto;
}

input[type=radio]:checked + div{
  div{
    display: flex;
  }
}



.hide-arrows{

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }
}

.backdrop-blur-sm {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}


/* width */
.scrollbar::-webkit-scrollbar {
  margin: 10px 0px;
  width: 4px;
  border-radius: 9999px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: #C4C4C4;
  border-radius: 9999px;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #F1EDE4;
  border-radius: 9999px;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #F1EDE4;
}

.custom-border {
  border-radius: 35px / 40px;
}

.markdown-body {

  font-family: Minion;

  h1, h2, h3, h4, h5, h6 {
    border-bottom: none;
  }

  img {
    margin-bottom: 24px;
  }
}

main > div {
  @apply  1441min:w-full;
}
.border-products{
  border-bottom: 1px solid #EBE9E6;
}

.border-products:last-child{
  border-bottom: none;
}

.dotted-deco{
  text-decoration-style: dotted;
}


.background-half{
  background: linear-gradient(to top, #F7E8E6 50%, white 50%);
}

.background-half-reverse{
  background: linear-gradient(to bottom, #F7E8E6 50%, white 50%);
}

// PROGRESS BAR 

.progressNum {
  margin: 0;
}

.progressBar {
  margin: 0;
}

.spec {
  margin: 0
}

//splide

.splide__pagination{
  color : #fbeccf;
  height: 0px;
  .is-active{
    background-color: #BEA97F !important;
   }
}

// map marker

.marker {
  background-image: url('/assets/icons/logocircle.svg');
  background-size: cover;
  width: 33.65px;
  height: 32px;
  cursor: pointer;
}
.mapboxgl-popup {
  max-width: 200px;
}
.mapboxgl-popup-content {
  position: relative;
  transform: translate(-14%, -125%);
  background-color: #fff;
  color: #000;
  font-family: 'Nunito';
  line-height: 24px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
  width: 335px;
}
.mapboxgl-popup-close-button {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.mapboxgl-popup-close-button:focus {
  outline: none;
}
.mapboxgl-popup-close-button::after {
  content: url('/assets/svg/close.svg');
  position: absolute;
  background: #fff;
  top: 0;
  right: 0;
}

.dmserif {
  font-family: 'DM Serif Display', serif;
}